import React from 'react'

import { Heading, QuestionIcon } from '@te-digi/styleguide'

const HeadingIconExample = () => (
  <>
    <Heading
      level={2}
      icon={<QuestionIcon />}
    >
      Heading with Icon, level 2 size default
    </Heading>
    <Heading
      level={2}
      icon={<QuestionIcon />}
      size={1}
    >
      Heading with Icon, level 2 size 1
    </Heading>
    <Heading
      level={2}
      icon={<QuestionIcon />}
      size={2}
    >
      Heading with Icon, level 2 size 2
    </Heading>
    <Heading
      level={2}
      icon={<QuestionIcon />}
      size={3}
    >
      Heading with Icon, level 2 size 3
    </Heading>

    <Heading
      level={3}
      icon={<QuestionIcon />}
      size={2}
    >
      Heading with Icon, level 3 size 2
    </Heading>

    <Heading
      level={3}
      icon={<QuestionIcon />}
      size={2}
    >
      Heading with Icon, level 3 size 2, long heading text long heading text
      long heading text
    </Heading>
  </>
)

export { HeadingIconExample }
