import React, { useState } from 'react'

import { Button, Focus, Heading } from '@te-digi/styleguide'

const HeadingFocusExample = () => {
  const [shouldFocus, setShouldFocus] = useState(false)

  return (
    <>
      {/* EXAMPLE_START */}
      <Focus enabled={shouldFocus}>
        <Heading
          onBlur={() => setShouldFocus(false)}
          tabIndex={-1}
        >
          Otsikko
        </Heading>
      </Focus>
      <Button onClick={() => setShouldFocus(true)}>Fokusoi</Button>
      {/* EXAMPLE_END */}
    </>
  )
}

export { HeadingFocusExample }
