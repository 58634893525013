import React from 'react'
import tokens from '@te-digi/styleguide-tokens'

import {
  DesktopIcon,
  Heading,
  Inline,
  List,
  ListItem,
  Paragraph,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TabletIcon,
  Text,
  VisuallyHidden
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { HeadingFocusExample } from '../../examples/react/HeadingFocusExample'
import { Link } from '../../components/Link'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'

import { HeadingIconExample } from '../../examples/react/HeadingIconExample'

const headings = [
  {
    size: 1,
    heading: 'Sivuotsikko (sivun pääotsikko)',
    tablet: 'xxxl',
    desktop: 'xxxxxl'
  },
  {
    size: 2,
    heading: 'Pääotsikko (käytetään tarvittaessa)',
    tablet: 'xxxl',
    desktop: 'xxxxl'
  },
  {
    size: 3,
    heading: 'Otsikko',
    tablet: 'xxl',
    desktop: 'xxl'
  },
  {
    size: 4,
    heading: 'Alaotsikko',
    tablet: 'xl',
    desktop: 'xl'
  },
  {
    size: 5,
    heading: 'Alaotsikko 2',
    tablet: 'lg',
    desktop: 'lg'
  },
  {
    size: 6,
    heading: 'Alaotsikko 3',
    tablet: 'md',
    desktop: 'md'
  }
] as const

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Heading"
    components={[{ component: Heading, restElement: true }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Sivulla tulee olla vain yksi <Code>&lt;h1&gt;</Code>
          -elementti.
        </ListItem>
        <ListItem>
          Otsikkoelementtien pitää olla loogisessa järjestyksessä{' '}
          <Code>&lt;h1&gt;</Code>
          -elementistä <Code>&lt;h6&gt;</Code>-elementtiin.
        </ListItem>
        <ListItem>Otsikkotasoja ei saa jättää välistä.</ListItem>
        <ListItem>
          Eri otsikkotasoilla (<Code>level</Code>) voi käyttää tarpeen vaatiessa
          eri otsikkotyylejä (<Code>size</Code>).
        </ListItem>
        <ListItem>
          Etusivulla ja pääsivulla (kts. <Link to="/mallit">mallit</Link>) on
          sallittua käyttää otsikon tehosteena <Code>primary</Code>-väriä.
        </ListItem>
      </List>
    </Section>
    <Section title="Otsikkotyylit">
      <Stack gap="lg">
        {headings.map((heading, index) => (
          <Table
            divider="horizontal"
            key={index}
          >
            <TableHeader>
              <TableRow>
                <TableHeaderCell colSpan={2}>
                  <Inline
                    gap="md"
                    wrap="wrap"
                  >
                    <>
                      {heading.size}. {heading.heading}
                    </>
                    <Code>
                      &lt;Heading level={'{'}
                      {heading.size}
                      {'}'} size={'{'}
                      {heading.size}
                      {'}'}&gt;
                    </Code>
                    <Code>
                      &lt;h{heading.size} class="h{heading.size}"&gt;
                    </Code>
                  </Inline>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {(['tablet', 'desktop'] as const).map(size => (
                <TableRow key={size}>
                  <TableCell
                    style={{ width: '1%' }}
                    verticalAlign="middle"
                  >
                    {size === 'desktop' && (
                      <DesktopIcon
                        block
                        size="lg"
                      />
                    )}
                    {size === 'tablet' && (
                      <TabletIcon
                        block
                        size="lg"
                      />
                    )}
                    <VisuallyHidden>{size}</VisuallyHidden>
                  </TableCell>
                  <TableCell>
                    <Text
                      as="p"
                      fontWeight="semi-bold"
                      lineHeight="xs"
                      size={heading[size]}
                    >
                      Lorem ipsum dolor sit amet
                    </Text>
                    <Text
                      as="p"
                      color="dark"
                      size="sm"
                    >
                      {tokens['font-size'][heading[size]]}, 600
                    </Text>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Stack>
    </Section>
    <Section title="Esimerkkejä">
      <Paragraph>
        Otsikko, jonka semanttinen taso on 1 (eli sivun pääotsikko) ja
        visuaalinen koko on 3.
      </Paragraph>
      <Playground>
        <Heading
          level={1}
          size={3}
          noMargin
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit
        </Heading>
      </Playground>
      <Playground format="html">
        <h1 className="h3 mb-0">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit
        </h1>
      </Playground>
      <SectionHeading>Ohje-painike</SectionHeading>
      <Playground>
        <Heading
          help="Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
          helpButtonLabel="Lisätietoa"
          level={3}
          noMargin
          size={3}
        >
          Quisque ligula eros ullamcorper quis
        </Heading>
      </Playground>
      <SectionHeading>Lisäotsikko</SectionHeading>
      <Paragraph>
        Lisäotsikkoa voi käyttää tarkentamaan otsikon sisältöä. Lisäotsikko on
        pelkästään visuaalinen täydennys otsikolle, eli sillä ei ole omaa
        otsikkotasoa.
      </Paragraph>
      <Playground>
        <Stack gap="xl">
          {([1, 2, 3, 4, 5, 6] as const).map(size => (
            <Heading
              key={size}
              level={3}
              noMargin
              size={size}
              overline="Sed posuere"
            >
              {size}. Lorem ipsum dolor sit amet
            </Heading>
          ))}
        </Stack>
      </Playground>
      <Playground>
        <Stack gap="xl">
          {([1, 2, 3, 4, 5, 6] as const).map(size => (
            <Heading
              key={size}
              level={3}
              noMargin
              size={size}
              subHeading="Lacinia quis facilisis sed sapien"
            >
              {size}. Quisque ligula eros ullamcorper quis
            </Heading>
          ))}
        </Stack>
      </Playground>
      <Playground format="html">
        <>
          <h4 className="h3">
            <span className="heading-overline">
              Lacinia quis facilisis sed sapien
            </span>{' '}
            Quisque ligula eros ullamcorper quis
          </h4>
          <h3 className="h4 mb-0">
            <span className="heading-overline">
              Lacinia quis facilisis sed sapien
            </span>{' '}
            Quisque ligula eros ullamcorper quis
          </h3>
        </>
      </Playground>
      <Playground format="html">
        <h3 className="h2 mb-0">
          Quisque ligula eros ullamcorper quis{' '}
          <span className="heading-subheading">
            Lacinia quis facilisis sed sapien
          </span>
        </h3>
      </Playground>
      <SectionHeading>Headline</SectionHeading>
      <Playground format="html">
        <>
          <h1 className="heading-headline">Headline h1</h1>
          <h2 className="heading-headline">Headline h2</h2>

          <div className="heading-icon heading-headline mb-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon icon-brand"
              data-icon="te-palvelut-fill"
              role="presentation"
            >
              <path d="M21.947 8.095c-.9-.9-2-.8-2.5.6-.6 1.8-1.6 3.9-2.7 5.9-1.2 2-2.5 3.9-3.7 5.3-.9 1.2-.5 2.1.8 2.4 1.3.3 2.5 0 3.9-1.1 1-.8 2.1-2.2 3.3-4.2 1.1-2 1.8-3.7 2-4.9.1-1.8-.2-3-1.1-4Zm-1.8-3c-.4-1.3-1.2-2.1-2.9-2.8-1.2-.4-3-.7-5.3-.7-2.4 0-4.1.3-5.3.7-1.7.7-2.5 1.5-2.9 2.8-.4 1.2.2 2.1 1.7 1.9 1.9-.4 4.2-.6 6.5-.6s4.6.2 6.5.6c1.5.2 2.1-.7 1.7-1.9Zm-10.1 10c2 1.2 3.7.1 3.8 0 1.3-.9 1.8-2.9 1.6-4.1-.2-1.9-1.5-2.2-1.7-2.3-1-.3-2.7-.3-3.7 0-1.6.5-1.6 2.1-1.7 2.3-.2 1.1.4 3.2 1.7 4.1Zm-8.1-7c-.9.9-1.2 2.1-.9 3.9.2 1.2.9 3 2 4.9 1.2 2.1 2.3 3.4 3.3 4.2 1.4 1.1 2.6 1.5 3.9 1.1 1.3-.3 1.7-1.3.8-2.4-1.3-1.3-2.6-3.2-3.8-5.2-1.1-2-2.1-4.1-2.7-5.9-.6-1.4-1.7-1.5-2.6-.6Z" />
            </svg>
            <h1 className="heading-headline mb-0">Otsikko kuvalla</h1>
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Otsikon fokusointi">
      <Paragraph>
        Fokus voidaan tarvittaessa siirtää otsikkoon, esim. helpottamaan
        näppäimistö- sekä ruudunlukijakäyttäjää jatkamaan moniosaisen lomakkeen
        täyttämistä. Huomaa <Code>tabIndex</Code> -1: otsikkoon ei ole tarkoitus
        siirtyä näppämistöllä navigoimalla.
      </Paragraph>
      <Paragraph noMargin>Katso myös</Paragraph>
      <List variant="unordered">
        <ListItem>
          <Link to="/muut/focus/#fokus-otsikossa">Focus: Focus otsikossa</Link>
        </ListItem>
        <ListItem>
          <Link to="/patternit/monisivuisen-lomakkeen-navigaatioo#saavutettavuus">
            Monisivuisen lomakkeen navigaatio
          </Link>
        </ListItem>
      </List>
      <Playground example={HeadingFocusExample} />
    </Section>

    <Section title="Ikonilla">
      <Playground>{HeadingIconExample()}</Playground>
      <Playground format="html">
        <>
          <div className="heading-icon h1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h1>Sivuotsikko (h1)</h1>
          </div>

          <div className="heading-icon h1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon icon-secondary"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h1>Sivuotsikko (h1) värillisellä kuvalla</h1>
          </div>

          <div className="heading-icon h2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h2>Pääotsikko (h2)</h2>
          </div>

          <div className="heading-icon h3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h3>Otsikko (h3)</h3>
          </div>

          <div className="heading-icon h4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h4>Alaotsikko (h4)</h4>
          </div>

          <div className="heading-icon h5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h5>Alaotsikko (h5)</h5>
          </div>

          <div className="heading-icon h6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h6>Alaotsikko (h6)</h6>
          </div>

          <div className="heading-icon h3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h3>Otsikko h3 koossa h3</h3>
          </div>

          <div className="heading-icon h2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h3>Otsikko h3 koossa h2</h3>
          </div>

          <div className="heading-icon h2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h3>
              Otsikko h3 koossa h2, pitkä otsikko joka saattaa mennä kahdelle
              riville
            </h3>
          </div>

          <div className="heading-icon h3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              data-icon="speech-bubble"
              role="presentation"
            >
              <path d="M22 21.089c.4-.3.8-.7.8-1.1 0-.219-.2-.358-.3-.4 0 0-1-.4-1.5-.9-.2-.2-.3-.7-.1-1 1.1-1.7 1.8-3.7 1.8-5.9 0-6-4.8-10.8-10.7-10.8-6 0-10.8 4.7-10.8 10.7 0 4.7 2.7 8.3 6.8 10.1 5.4 2.2 11.1 1.1 14-.7Z" />
            </svg>
            <h2>
              Pääotsikko h2 koossa h3, pitkä otsikko joka saattaa mennä kahdelle
              riville
            </h2>
          </div>
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
